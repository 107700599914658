import * as React from "react";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "../components/AppBar";
import Toolbar from "../components/Toolbar";
import { Link as RouterLink } from "react-router-dom";
import Button from '@mui/material/Button';

const image = {
  height: 55,
  my: 4,
};


function AppAppBar() {
  return (
    <div>
      <AppBar position="fixed">
        <Toolbar sx={{ flex: 2, display: "flex", justifyContent: "center" }}>
          <Box />
          <Link
            variant="h6"
            underline="none"
            color="inherit"
            component={RouterLink}
            to="/"
            sx={{ fontSize: 24 }}
          >
            <Box
                  component="img"
                  src="/sc_logo_long.svg"
                  alt="Serious Connect logo"
                  sx={image}
                />
          </Link>


          <Box sx={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
      
        <React.Fragment>
          <Button variant="contained">
          <Link color="inherit" href="https://seriousconnect.velents.com/">
            Openings
            </Link>
          </Button>
      </React.Fragment>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}

export default AppAppBar;
