import React from "react";
import "./App.css";
import Home from "./serious/Home";
import { BrowserRouter as Router, useParams, Switch, Route } from "react-router-dom";
import Privacy from "./serious/Privacy";
import SignIn from "./serious/SignIn";
import SignUp from "./serious/SignUp";
import ForgotPassword from "./serious/ForgotPassword";
import Terms from "./serious/Terms";
import Openings from "./serious/Openings";
import About from "./serious/About";
import Schedule from "./serious/Schedule";
import Categories from "./serious/Categories";

function App() {

  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route path="/forgot-password">
            <ForgotPassword />
          </Route>
          <Route path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/sign-in">
            <SignIn />
          </Route>
          <Route path="/schedule">
            <Schedule />
          </Route>
          <Route path="/privacy">
            <Privacy />
          </Route>
          <Route path="/categories/:category" children={<Categories />} />
          <Route path="/terms">
            <Terms />
          </Route><Route path="/openings">
            <Openings />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
