import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
// import Markdown from "./modules/components/Markdown";
import Typography from "./modules/components/Typography";
import AppAppBar from "./modules/views/AppAppBar";
import AppFooter from "./modules/views/AppFooter";
import withRoot from "./modules/withRoot";

function Openings() {

  return (
    <React.Fragment>
      <AppAppBar />
      <Container>
        <Box sx={{ mt: 25, mb: 50 }}>
          <Typography variant="h3" gutterBottom marked="center" align="center">
            Openings
          </Typography>
          <iframe src="https://seriousconnect.velents.com" title="Job Openings" width="100%" height="1500"></iframe>
        </Box>
      </Container>
      <AppFooter />
    </React.Fragment>
  );
}

export default withRoot(Openings);
