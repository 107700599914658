import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";
import { Link as RouterLink } from "react-router-dom";

function Copyright() {
  return (
    <React.Fragment>
      {"© "}
      <Link color="inherit" href="https://seriousconnect.com/">
        SeriousConnect
      </Link>{" "}
      {new Date().getFullYear()}
    </React.Fragment>
  );
}

const iconStyle = {
  width: 48,
  height: 48,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "primary.light",
  mr: 1,
  "&:hover": {
    bgcolor: "secondary.light",
  },
};


export default function AppFooter() {
  return (
    <Typography
      component="footer"
      sx={{ display: "flex", bgcolor: "secondary.light" }}
    >
      <Container sx={{ my: 8, display: "flex" }}>
        <Grid container spacing={5}>
          <Grid item xs={6} sm={4} md={3}>
            <Grid
              container
              direction="column"
              justifyContent="flex-end"
              spacing={2}
              sx={{ height: 120 }}
            >
              <Grid item sx={{ display: "flex" }}>
                <Box component="a" href="https://www.facebook.com/SeriousConnectLLC/" sx={iconStyle}>
                  <img src="/appFooterFacebook.png" alt="Facebook" />
                </Box>
                <Box
                  component="a"
                  href="https://twitter.com/seriousconnect"
                  sx={iconStyle}
                >
                  <img src="/appFooterTwitter.png" alt="Twitter" />
                </Box>
                <Box
                  component="a"
                  href="https://www.linkedin.com/company/seriousconnect/"
                  sx={iconStyle}
                >
                  <img src="/appFooterLinkedIn.png" alt="LinkedIn" />
                </Box>
              </Grid>
              <Grid item>
                <Copyright />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Typography variant="h6" marked="left" gutterBottom>
              Legal
            </Typography>
            <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link component={RouterLink} to="/terms/">
                  Terms
                </Link>
              </Box>
              <Box component="li" sx={{ py: 0.5 }}>
                <Link component={RouterLink} to="/privacy/">
                  Privacy
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={8} md={4}>
            <Typography variant="h6" marked="left" gutterBottom>
              Company
            </Typography>
              <Box component="ul" sx={{ m: 0, listStyle: "none", p: 0 }}>
                <Box component="li" sx={{ py: 0.5 }}>
                  <Link component={RouterLink} to="/about/">
                    About
                  </Link>
                </Box>
                <Box component="li" sx={{ py: 0.5 }}>
                <Link color="inherit" href="https://seriousconnect.velents.com/">
            Openings
            </Link>
                </Box>
              </Box>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
