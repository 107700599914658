import * as React from "react";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "../components/Typography";

const item: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  px: 5,
};

function ProductValues() {
  return (
    <Box
      component="section"
      sx={{ display: "flex", overflow: "hidden", bgcolor: "secondary.light" }}
    >
      <Container sx={{ mt: 15, mb: 30, display: "flex", position: "relative" }}>
        <Box
          component="img"
          src="/productCurvyLines.png"
          alt="curvy lines"
          sx={{ pointerEvents: "none", position: "absolute", top: -180 }}
        />
        <Grid container spacing={5}>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/productValues1.svg"
                alt="tools"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                The best sourcing tools
              </Typography>
              <Typography variant="h5">
                {
                  "Our powerful sourcing tools enable us to find and match top-tier candidates with the right job openings faster than traditional methods. And our streamlined hiring processes ensure maximum efficiency in our operations."
                }
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/productValues2.svg"
                alt="graph analytics"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Productivity Analytics
              </Typography>
              <Typography variant="h5">
                {
                  "We are also serious about our work, and we measure every part of our operations using analytics. We are constantly looking for ways to increase our productivity, and that's what it takes to bring you the best."}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={item}>
              <Box
                component="img"
                src="/productValues3.svg"
                alt="people search"
                sx={{ height: 55 }}
              />
              <Typography variant="h6" sx={{ my: 5 }}>
                Quality Candidates
              </Typography>
              <Typography variant="h5">
                {"We are focused on high-quality, not quantity, which is why we bring only the best candidates to companies nationwide. You won't find random resumes and filler, you'll find qualified candidates who are ready to join the team."}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default ProductValues;
